<template>
  <div class="p-5 history-modal text-center">
    {{content.message}}<br><br>
    <small class="text-muted">
      <input type="checkbox" v-model="checkbox"> Don't ask me again
    </small>
    <br>
    <div class="w-100 mt-3">
      <div class="btn border-0 px-4 py-2 btn-primary" v-on:click="confirmMethod" v-html="content.buttons[0]"></div>&nbsp;
      <div class="btn border-0 px-4 py-2 btn-dark bg-escalated" v-on:click="cancelMethod" v-html="content.buttons[1]"></div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: Object,
      toggleGitTicket: Function
    },
    data() {
      return {
        checkbox: false
      }
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      rememberState: function(){
        let ticketStates = localStorage.getObject('ticketStates') || {}
        if(this.checkbox)
          ticketStates[this.content.ticket._id] = {
            ignore: true
          }
        else if(ticketStates[this.content.ticket._id])
          ticketStates[this.content.ticket._id] = {
            ignore: false
          }
        localStorage.setObject('ticketStates', ticketStates)
      },
      confirmMethod: async function(){
        this.rememberState()
        this.loader(true)
        if(this.content.type === 'git-reopen'){
          let resp = 'Gitlab ticket reopened!'
          if(!await this.toggleGitTicket('reopen'))
            resp = 'Something went wrong, please try again.'
          else{
            this.$alertify({
              group: 'notification',
              title: 'Ticket Update',
              type: 'success',
              text: resp
            })
            await this.$sleep(1500);
            this.$modal.toggle('git-actions');
            window.location.href = `/tickets/${this.content.ticket.ticketNumber}`
          }
        }
        this.loader(false)
      },
      cancelMethod: function(){
        this.rememberState()
        this.$modal.toggle('git-actions');
      }
    }
  }
</script>
