<template>
  <div ref="editor" class="quill-editor"></div>
</template>

<script>
export default {
  name: 'QuillEditor',
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Start typing...'
    },
    toolbar: {
      type: Array,
      default: () => [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'font': [] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['bold', 'italic', 'underline'],
        ['link', 'blockquote', 'code-block'],
        [{ 'align': [] }],
        ['image'],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['clean']
      ]
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (typeof Quill !== 'undefined') {
      this.editor = new Quill(this.$refs.editor, {
        theme: 'snow',
        placeholder: this.placeholder,
        modules: {
          toolbar: this.toolbar
        }
      });

      this.editor.on('text-change', () => {
        this.$emit('input', this.editor.root.innerHTML);
      });

      if (this.value) {
        this.editor.root.innerHTML = this.value;
      }

      if (this.readOnly) {
        this.editor.disable();
      }

      if (this.editor.root.innerHTML.trim() !== '') {
        this.editor.root.setAttribute('data-placeholder', '');
      }
    } else {
      console.error('Quill is not loaded');
    }
  },
  watch: {
    value(newVal) {
      if (this.editor && this.editor.root.innerHTML !== newVal) {
        this.editor.root.innerHTML = newVal;
      }
    },
    readOnly(newVal) {
      if (this.editor) {
        if (newVal) {
          this.editor.disable();
        } else {
          this.editor.enable();
        }
      }
    }
  }
};
</script>

<style scoped>
.quill-editor {
  height: 300px;
}

::v-deep .ql-editor {
  line-height: 1.6;
  padding: 10px;
  font-size: 16px;
  color: #34495e;
}

::v-deep .ql-editor p {
  margin-bottom: 1em;
}
</style>
