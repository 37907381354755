<template>
  <div class="p-5 history-modal">
    <div v-if="histories && Object.keys(histories).length > 0">
      <label class="text-header" v-for="(history, key) in histories" v-bind:key="key">
        <i class="far fa-circle mb-3"></i>Iteration no. {{key}}<br>
        <span v-if="history.startAt">
          <i class="far fa-circle text-white"></i>Issued at:
          <div class="mb-2">{{history.startAt | formatDate}}</div>
        </span>
        <span v-if="history.resolvedAt">
          <i class="far fa-circle text-white"></i>Resolved at:
          <div>{{history.resolvedAt | formatDate}}</div>
        </span>
      </label>
    </div>
    <label class="text-center text-header p-0 m-0" v-else>
      No iteration yet
    </label>
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    props: {
      histories: Object
    },
    filters: {
      formatDate: function (date){
        return moment(date, 'YYYY/MM/DD HH:mm:ss').format("ddd, DD MMMM YYYY, hh:mm A")
      }
    }
  }
</script>
