<template>
  <div>
    <div class="title-path">
      <a href="/">Home</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a href="/tickets">Tickets</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a :href="`/tickets/${ticketNumber}`">{{ticketNumber}}</a>
    </div>
    <div class="row issue-wrapper" v-if="ticket._id">
      <div class="col col-auto-9 issue-col p-4">
        <div class="card card-light p-5">
          <h5 class="row mb-4">
            <div class="col col-auto p-0 cursor-pointer" v-tooltip="ticket.isActive ? `Ticket is currently active` : `Ticket has been inactive for a while`">
              <i class="far fa-check-circle" v-bind:class="ticket.isActive ? `text-grass` : `text-secondary`"></i>
            </div>
            <div class="col py-0 px-2">

              <!-- issue -->
              <input type="text" class="form-invisible form-issue" v-model="ticketForm.issue" v-if="formControl.issue" v-on:keyup.enter.prevent>
              <span v-else>
                {{ticketForm.issue}}<br>
              </span>
              <small class="text-muted">
                <small>
                  By <span class="cursor-pointer" v-tooltip="{ content: ticket.createdBy && ticket.createdBy.name ? `${ticket.createdBy.email} | ${ticket.client.code}` : ticket.client.name, placement: 'right' }">{{ticket.createdBy && ticket.createdBy.name ? ticket.createdBy.name : ticket.client.code}}</span>
                  reported on {{ticket.createdAt | formatDate}}
                </small>
              </small><br>
              <div class="btn btn-sm btn-primary mt-2 cursor-default" v-on:click="copyTicketNumber">
                <i class="far fa-copy"></i>&nbsp;&nbsp;{{ticketNumber}}
              </div>
              <span class="mx-1"></span>
              <div class="btn btn-sm mt-2 text-white cursor-default" v-bind:class="`bg-${ticket.status}`" v-on:click="toggleHistories" v-tooltip="ticket.status === 'closed' ? `Ticket has been closed` : `Ticket is currently ${$statusTitle[ticket.status]}`">
                <i class="far fa-check-circle"></i>&nbsp;&nbsp;{{$statusTitle[ticket.status]}}
              </div>
              <span class="mx-1"></span>
              <div class="btn btn-sm mt-2 text-white bg-warning cursor-default" v-on:click="toggleLinkedTickets">
                <i class="fas fa-link"></i>&nbsp;&nbsp;Linked Tickets
              </div>
            </div>
            <div class="col col-auto p-0">
              <a v-tooltip="`Duplicate ticket`" class="btn btn-sm btn-info cursor-default" :href="`/tickets/new?_orig=${ticketNumber}`" v-if="this.user.role !== 'Customer'">
                <i class="fas fa-copy"></i>
              </a>
              <span class="mx-1"></span>
              <div v-tooltip="`Delete ticket`" class="btn btn-sm btn-warning cursor-default" v-on:click="toggleDelete" v-if="this.user.role !== 'Customer' && this.user.role !== 'Agent' && this.user._allowDelete">
                <i class="fas fa-trash"></i>
              </div>
              <span class="mx-1" v-if="this.user.role !== 'Customer' && this.user.role !== 'Agent' && this.user._allowDelete"></span>
              <div v-tooltip="`Toggle fullscreen`" class="btn btn-sm btn-secondary btn-disabled text-right" v-on:click="toggleProperties">
                <i class="fas" v-bind:class="showProperties ? 'fa-expand' : 'fa-compress'"></i>
              </div>
            </div>
          </h5>

          <!-- description -->
          <div v-if="formControl.description">
            <QuillEditor 
              v-model="ticketForm.description"
              class="mb-4 border-radius-0 form-plain"
              style="height: 800px;"  
              placeholder="Describe your concern or issue here."
              :toolbar="toolbarConfig"
            />
          </div>
          <div v-else>
            <QuillEditor 
              v-model="ticketForm.description"
              class="mb-4 border-radius-0 form-plain"
              style="height: 600px; border: none" 
              placeholder="Describe your concern or issue here."
              :toolbar="false"
              :readOnly="true"
            />
          </div>
          <!-- <div class="tox-tinymce-rounded-border" v-if="formControl.description">
            <tiny-editor
              v-model="ticketForm.description"
              :api-key="this.$tinyMCEAPIKey"
              class="m-3 bg-dark mr-0 p-0"
              placeholder="Describe your concern or issue here."
              :init="{
                ...tinyMCEconfig,
                height: 800
              }"
            />
          </div>
          <div class="tox-tinymce-borderless" v-else>
            <tiny-editor
              v-model="ticketForm.description"
              :disabled="true"
              :api-key="this.$tinyMCEAPIKey"
              class="m-3 mr-0 p-0"
              placeholder="Describe your concern or issue here."
              :init="{
                ...tinyMCEconfig,
                height: 600,
                menubar: false,
                toolbar: false
              }"
            />
          </div> -->
          <div class="mt-4" v-if="ticketForm.forms && ticketForm.forms.length > 0">
            <div class="mx-3" v-for="form in ticketForm.forms" v-bind:key="form.key">
              <h6 class="text-dark text-header">{{form.name}}</h6>
              <input v-model="form.value" type="text" class="form-control" :placeholder="form.name" v-if="formControl.description && form.type === 'text'">
              <input v-model="form.value" type="number" class="form-control" :placeholder="form.name" v-else-if="formControl.description && form.type === 'number'">
              <tiny-editor
                  v-model="form.value"
                  v-else-if="formControl.description && form.type === 'textarea'"
                  placeholder="Write something here"
                  :api-key="$tinyMCEAPIKey"
                  :init="tinyMCEconfig"
              />
              <ModelSelect
                v-model="form.value"
                :placeholder="form.name"
                :options="$buildOptions(form.options)"
                v-else-if="formControl.description && form.type === 'select'"
              >
              </ModelSelect>
              <div
                class="issue-description mx-3 p-0"
                v-html="imgViewerReady(form.value)"
                v-else
              >
              </div>
              <br>
            </div>
          </div>
          <div class="mt-3 attachment-list" v-if="ticket.attachments && ticket.attachments.length > 0">
            <div class="attachment-item" v-for="attachment in ticket.attachments" v-bind:key="attachment._id" v-on:click="$openFile(attachment)" v-tooltip="attachment.originalname">
              <i class="col-2 fas" v-bind:class="getFileIcon(attachment)"></i>
              <div>
                <span>{{attachment.originalname}}</span>
                <label>{{attachment.size | convertSize}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-light p-5 mt-4 mb-1" v-if="ticket._id">
          <CommentsComponent @loader="loader($event)" :ticket="ticket"></CommentsComponent>
        </div>
      </div>
      <transition name="fade">
        <div class="col-md-3 issue-col p-4 issue-properties" v-if="showProperties">
          <h6 class="pb-3 text-bolder">
            <span class="text-left d-inline-block w-50">Issue {{ticket.status | ticketState}}</span>
            <span class="text-right d-inline-block w-50 text-muted">
              <span class="cursor-pointer" v-tooltip="'Show ticket movement'" v-on:click="toggleHistories">
                <i class="fas fa-list-ul"></i>
              </span>&nbsp;&nbsp;
              <span class="cursor-pointer" v-tooltip="'Show iteration history'" v-on:click="toggleIterationHistories">
                <i class="fas fa-history"></i>&nbsp;
                {{ ticket.iterationCount }}
              </span>&nbsp;&nbsp;
              <span class="cursor-pointer" v-tooltip="'Upon being resolved, ticket will automatically be closed after ' + ticket.settings.autoClose + ' days'" v-if="ticket && ticket.settings && ticket.settings.autoClose">
                <i class="fas fa-calendar-check"></i>
              </span>
            </span>
          </h6>
          <label class="text-header pointer" v-if="ticket.acknowledgedAt" v-tooltip="`Responded within ${$getDuration(ticket, 'acknowledgedAt')}`">
            <i class="far fa-circle"></i>First acknowledged on<br>
            <div>{{ticket.acknowledgedAt | formatDate}}</div>
          </label>
          <label class="text-header pointer" v-if="ticket.resolvedAt" v-tooltip="`Resolved within ${$getDuration(ticket, 'resolvedAt')}`">
            <i class="far fa-circle"></i>Resolution date<br>
            <div>{{ticket.resolvedAt | formatDate}}</div>
          </label>
          <label class="text-header pointer" v-if="ticket.closedAt" v-tooltip="`Closed after ${$getDuration(ticket, 'closedAt')}`">
            <i class="far fa-circle"></i>Ticket closed on<br>
            <div>{{ticket.closedAt | formatDate}}</div>
          </label>
          <hr class="mx-3" v-if="ticket.acknowledgedAt || ticket.resolvedAt || ticket.closedAt">

          <!-- Labels -->
          <div class="property-dynamic mb-4" v-if="formControl.labels">
            <h6 class="text-dark text-header">Ticket Labels</h6>
            <MultiSelect
              :options="options.labels"
              :selected-options="ticketForm.labels"
              placeholder="Select ticket labels"
              @select="(items, last) => onSelect(items, last, 'labels')"
            >
            </MultiSelect>
          </div>
          <div class="property-static" v-else-if="ticketForm.labels.length > 0">
            <h6 class="text-dark text-header">Ticket Labels</h6>
            <div class="py-2 mb-4">
              <div class="text-muted text-center p-3" v-if="ticketForm.labels.length === 0">No ticket labels</div>
              <span
                v-for="label in ticketForm.labels"
                v-bind:key="label.key"
                style="border-radius: 1000px; font-size: 80%; margin-right: 3px"
                class="btn btn-sm btn-primary btn-rounded px-3"
              >
                {{label.text}}
              </span>
            </div>
          </div>

          <!-- Project -->
          <div class="property-dynamic mb-4" v-if="formControl.project">
            <h6 class="text-dark text-header">Gitlab Project</h6>
            <ModelSelect
              v-model="ticketForm.project"
              placeholder="Select Gitlab project"
              :options="options.projects"
            >
            </ModelSelect>
          </div>
          <div class="property-static" v-else>
            <h6 class="pt-2 text-dark text-header" v-if="ticket.project && ticket.project.gitLab">Gitlab Project</h6>
            <div class="row p-3 cursor-pointer" v-if="ticket.project && ticket.project.gitLab" v-on:click="openGitTicket">
              <!-- :href="`${ticket.project.gitLab.url}/-/issues/${ticket.gitLabId}`" target="_blank" -->
              <div class="col-auto p-0">
                <img class="agent-avatar cursor-pointer" :src="$getAvatar(`/images/avatars/gitAvatar.svg`)">
              </div>
              <div class="col p-0">
                <label class="text-property text-bolder cursor-pointer">
                  {{ticket.project.gitLab.title}}<br>
                  <div v-if="ticket.gitTicket && ticket.gitTicket.iid" class="mt-05">
                    Issue no. {{ ticket.gitTicket.iid }} | {{ ticket.gitTicket.state | capitalize }}<br>
                    <span v-for="label in ticket.gitTicket.labels" v-bind:key="label">
                      <div class="btn btn-sm border-rounded border-0 btn-primary py-0 px-2 mt-1">
                        <small>{{ label }}</small>
                      </div>&nbsp;
                    </span>
                  </div>
                  <a v-else :href="ticket.gitLabId ? `${ticket.project.gitLab.url}/-/issues/${ticket.gitLabId}` :  `${ticket.project.gitLab.url}/-/issues/`" target="_blank">Go to Gitlab page</a>
                </label>
              </div>
            </div>
          </div>

          <!-- agent -->
          <div class="property-dynamic" v-if="formControl.agent && this.user._allowReassign">
            <h6 class="pt-2 text-dark text-header">Assigned Agent</h6>
            <ModelSelect
              v-model="ticketForm.agent"
              placeholder="Select assigned agent"
              :options="options.agents"
              :isDisabled="!(ticketForm.project && ticketForm.project != {} && ticketForm.project != '')"
            >
            </ModelSelect>
          </div>
          <div class="property-static" v-else>
            <h6 class="text-dark text-header" v-if="ticket.agent">Assigned Agent</h6>
            <div class="row p-3 cursor-pointer" v-if="ticket.agent">
              <a class="col-auto p-0" :href="`/users/${ticket.agent._id}`" target="_blank">
                <img class="agent-avatar cursor-pointer" :src="$getAvatar(ticket.agent.avatar)">
              </a>
              <div class="col-auto p-0">
                <label class="text-property text-bolder cursor-pointer">
                  {{ticket.agent.name}}<br>
                  <a v-tooltip="`Send email to ${ticket.agent.email}`" :href="`mailto:${ticket.agent.email}`">{{ticket.agent.email}}</a>
                </label>
              </div>
            </div>
          </div>

          <!-- issueClassification, status, priority, agentPriority -->
          <div class="property-dynamic" v-if="!user._isCustomer && !['resolved', 'closed'].includes(ticket.status)">
            <h6 class="pt-4 text-dark text-header">Move Status</h6>
            <ModelSelect
              v-model="ticketForm.status"
              placeholder="Select issue status"
              :options="options.status.filter(r => !r.disabled)"
              :isDisabled="!formControl.status"
            >
            </ModelSelect>
          </div>
          <div class="property-dynamic" v-if="!user._isCustomer && ticket.status !== 'closed'">
            <h6 class="pt-4 text-dark text-header">Issue Classification</h6>
            <ModelSelect
              v-model="ticketForm.issueClassification"
              placeholder="Select issue classification"
              :options="options.issueClassification"
              :isDisabled="!formControl.issueClassification"
            >
            </ModelSelect>
            <h6 class="pt-4 text-dark text-header">Client Severity</h6>
            <ModelSelect
              v-model="ticketForm.priority"
              placeholder="Select client severity"
              :options="options.priority"
              :isDisabled="!formControl.priority"
            >
            </ModelSelect>
            <h6 class="pt-4 text-dark text-header">Agent Severity</h6>
            <ModelSelect
              v-model="ticketForm.agentPriority"
              placeholder="Select agent severity"
              :options="options.priority"
              :isDisabled="!formControl.agentPriority"
            >
            </ModelSelect>
          </div>
          <div class="property-static" v-if="user._isCustomer || ticket.status === 'closed'">
            <div class="row">
              <div class="col-lg-6" v-if="ticket.status">
                <h6 class="pt-2 text-dark text-header">Ticket Status</h6>
                <label class="text-property">{{$statusTitle[ticket.status]}}</label>
              </div>
              <div class="col-lg-6" v-if="ticket.issueClassification">
                <h6 class="pt-2 text-dark text-header">Classification</h6>
                <label class="text-property">{{ticket.issueClassification}}</label>
              </div>
              <div :class="ticket.status != 'pending' ? 'col-lg-6' : 'col-lg-12'" v-if="ticket.priority">
                <h6 class="pt-2 text-dark text-header">Client Severity</h6>
                <div v-if="ticket.status != 'pending'">
                  <label class="text-property">{{ticket.priority | priorityName}}</label>
                </div>
                <div class="pt-2" v-else>
                  <ModelSelect
                    v-model="ticketForm.priority"
                    placeholder="Select client severity"
                    :options="options.priority"
                    :isDisabled="!formControl.priority"
                  >
                  </ModelSelect>
                </div>
              </div>
              <div class="col-lg-6" v-if="ticket.agentPriority">
                <h6 class="pt-2 text-dark text-header">Agent Severity</h6>
                <label class="text-property">{{ticket.agentPriority | priorityName}}</label>
              </div>
            </div>
          </div>
          <div>&nbsp;</div>
          <div class="form-control btn btn-warning p-2 mb-2" v-on:click="updateTicket(false, true)" v-if="user._isManager && ticket.status === 'acknowledged' && ticket.iterationCount === 1">
            <i class="fas fa-undo p-0 mb-05 small"></i>&nbsp;
            Release ticket
          </div>
          <div class="form-control btn btn-primary p-2" v-on:click="updateTicket(false, false)" v-if="((formControl.issue && this.user._isCustomer) || !this.user._isCustomer) && formControl.button && formControl.button !== 'Reopen'">
            <i class="btn fas fa-save p-0 text-light mb-05"></i>&nbsp;
            {{formControl.button}} ticket
          </div>
          <div class="btn-group w-100 mt-4" v-if="formControl.button === 'Reopen'">
            <div class="form-control btn btn-warning p-2" v-on:click="updateTicket(false, false)">
              <i class="fas fa-history p-0 mb-0"></i>&nbsp;
              {{formControl.button}}
            </div>
            <div class="form-control btn btn-secondary p-2" v-on:click="updateTicket(true, false)" v-if="user._isCustomer">
              <i class="btn fas fa-times-circle p-0 text-light mb-05"></i>&nbsp;
              Close
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="loader-screen bg-light" v-else>
      <div>
        <i class="fas fa-spinner animate-spin"></i>
        <p class="animate-pulse">Please wait</p>
      </div>
    </div>
    <modal name="git-card" :transition="`fade`" :width="`520px`" :height="`auto`" :scrollable="true">
      <GitCardComponent @loader="loader($event)" :ticket="ticket"/>
    </modal>
    <modal name="git-actions" :transition="`fade`" :width="`350px`" :height="`auto`" :scrollable="true">
      <GitActionsComponent @loader="loader($event)" :toggleGitTicket="toggleGitTicket" :content="content"/>
    </modal>
    <modal name="ticket-history" :transition="`fade`" :width="`400px`" :height="`auto`" :scrollable="true">
      <TicketHistoryComponent :unfilteredHistories="ticket.history"/>
    </modal>
    <modal name="linked-tickets" @loader="loader($event)" :transition="`fade`" :width="`500px`" :height="`auto`" :scrollable="true">
      <LinkedTicketsComponent :ticket="ticket"/>
    </modal>
    <modal name="iteration-history" :transition="`fade`" :width="`400px`" :height="`auto`" :scrollable="true">
      <IterationHistoryComponent :histories="ticket.iterationHistory"/>
    </modal>
    <modal name="delete-ticket" :transition="`fade`" :width="`350px`" :height="`auto`" :scrollable="true">
      <DeleteTicketComponent :id="ticket._id" :ticketNumber="ticket.ticketNumber"/>
    </modal>
  </div>
</template>

<script>
  import moment from 'moment'
  import { ModelSelect, MultiSelect } from 'vue-search-select'

  import GitActionsComponent from './TicketModals/GitActions.vue'
  import GitCardComponent from './TicketModals/GitCard.vue'
  import TicketHistoryComponent from './TicketModals/TicketHistory.vue'
  import IterationHistoryComponent from './TicketModals/IterationHistory.vue'
  import LinkedTicketsComponent from './TicketModals/LinkedTickets.vue'
  import DeleteTicketComponent from './TicketModals/DeleteTicket.vue'
  import CommentsComponent from './Comments/Comments.vue'
  import QuillEditor from "../../components/Quills/QuillEditor.vue"

  export default {
    components: {
      GitActionsComponent,
      GitCardComponent,
      TicketHistoryComponent,
      LinkedTicketsComponent,
      IterationHistoryComponent,
      DeleteTicketComponent,
      CommentsComponent,
      ModelSelect,
      MultiSelect,
      QuillEditor
    },
    props: {
      ticketNumber: String
    },
    data() {
      return {
        editorContent: '',
        user: this.$userData ? this.$userData.user : { restrictions: {} },
        users: [],
        projects: [],
        project: {},
        projectCounter: 0,
        content: {
          message: '',
          buttons: ['Yes', 'No'],
          ticket: {}
        },
        formControl: {
          issue: false,
          description: false,
          status: false,
          labels: false,
          project: false,
          agent: false,
          issueClassification: false,
          priority: false,
          agentPriority: false,
          reopen: false,
          button: 'Save'
        },
        toolbarConfig: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'font': [] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ['bold', 'italic', 'underline'],
          ['link', 'blockquote', 'code-block'],
          [{ 'align': [] }],
          ['image'],
          [{ 'script': 'sub'}, { 'script': 'super' }],
          [{ 'indent': '-1'}, { 'indent': '+1' }],
          [{ 'direction': 'rtl' }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'align': [] }],
          ['clean']
        ],
        ticket: {
          _id: '',
          issue: '',
          description: '',
          status: '',
          priority: '',
          client: {},
          customer: {},
          settings: {},
          createdAt: '',
          updatedAt: '',
          iterationCount: 0,
          ticketNumber: '',
          channelId: '',
          acknowledgedAt: '',
          agent: {},
          iterationHistory: {},
          manager: {},
          project: {},
          inProgressAt: '',
          escalatedAt: '',
          gitLabId: '',
          resolvedAt: '',
          attachments: [],
          closedAt: '',
          history: [],
          gitTicket: {},
          labels: []
        },
        ticketForm: {
          labels: [],
          agent: {},
          project: {},
          status: {},
          priority: {},
          agentPriority: {},
          issue: '',
          description: '',
          issueClassification: {}
        },
        options: {
          labels: this.$labels,
          issueClassification: this.$issueClassifications,
          status: this.$statuses,
          priority: this.$priorities,
          projects: [],
          agents: []
        },
        gitLabId: false,
        sampleSelect: {},
        showProperties: true,
        tinyMCEconfig: {
          height: 300,
          menubar: true,
          menu: {
            file: { title: 'File', items: 'preview | print | help' },
            help: { title: 'Help', items: [] }
          },
          font_formats:
`Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Lato=lato; Oswald=oswald; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats`,
          content_style: `@import url('https://fonts.googleapis.com/css2?family=Lato'); body { font-family: 'Lato' }`,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            'undo redo | fontselect fontsizeselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | link image | insertfile | \
            bullist numlist outdent indent | removeformat'
        }
      }
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      toggleProperties: function(){
        this.showProperties = !this.showProperties
      },
      handleIssueInput: function(e){
        this.ticketForm.issue = e.target.innerText
      },
      onSelect: function (items, last, filter_key) {
        this.ticketForm[filter_key] = items
      },
      async getUsers(){
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/users?role=Manager,Agent&client=${this.ticket.client._id}`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.users = response.data.users.filter(r => r.roles === "Agent" || (r.roles === "Manager" && r.isAgent))
          this.options.agents = this.users.map(r => {
            return { key: r._id, text: r.name, value: r._id, record: r }
          }).filter(r => r.record.isActive)
        } catch (error) {
          console.log(error)
        }
      },
      async getProjects(){
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/projects?client=${this.ticket.client._id}`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.projects = response.data.projects
          this.options.projects = this.projects.filter(r => !r.disabled).map(r => {
            return { key: r._id, text: `${r.bunit.name} | ${r.gitLab.title}`, value: r._id, record: r }
          })
        } catch (error) {
          console.log(error)
        }
      },
      async getTicket(){
        this.projectCounter = 0
        this.loader(true)
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/ticket?ticketNumber=${this.ticketNumber}`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.ticket = response.data.ticket
          await this.validateUser(response.data.isValid, response.data.exists)
          if (response.data.isValid){
            this.ticket.status = this.ticket.status || 'pending'
            this.ticket.history = response.data.histories
            if(this.ticket._id){
              if(this.ticket.client.archived){
                this.$alertify({
                  group: 'notification',
                  title: `Access denied`,
                  type: 'warning',
                  text: `Your client account has been archived. Please contact any iRipple administrator for more details.`
                })
                await this.$sleep(500);
                this.$router.push('/tickets')
              }
              if(this.ticket.project && this.ticket.project.gitLab.id && this.ticket.gitLabId)
                await this.getGitTicket()
              this.setForms()
              this.propagateForm()
              this.filterOptions()
            }
            this.options.labels = this.$labels.filter(r => r.record.clients.length == 0 || r.record.clients.map(q => q.code).includes(this.ticket.client.code))
          }
          this.loader(false);
          this.getUsers()
          this.getProjects()
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      getGitTicket: async function (){
        try {
          this.loader(true)
          const response = await this.$http.get(
            `${this.$gitEndpoint}/projects/${this.ticket.project.gitLab.id}/issues/${this.ticket.gitLabId}`,
            {
              headers: {
                'Authorization': `Bearer ${this.$gitToken}`,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.ticket.gitTicket = response.data
          let ticketStates = localStorage.getObject('ticketStates')
          if(this.user._isAssignedAgent && (!ticketStates || (ticketStates && !ticketStates[this.ticket._id]) || (ticketStates && !ticketStates[this.ticket._id].ignore))){
            if(['pending', 'acknowledged', 'inProgress', 'escalated', 'waitingForClient'].includes(this.ticket.status) && this.ticket.gitTicket.state === 'closed'){
              this.content.type = 'git-reopen'
              this.content.message = 'Gitlab ticket is currently closed. Would you like to reopen it?'
              this.content.ticket = this.ticket
              this.content.buttons = ['Yes', 'No']
              this.$modal.toggle('git-actions');
            }
          }
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      openGitTicket: function(){
        if(this.ticket.gitTicket)
          this.$modal.toggle('git-card');
      },
      buildUpdateData: function(forClosure){
        let data = {}
        switch(this.ticket.status){
          case 'pending':
            if(this.user._isCustomer){
              if(this.ticket.issue != this.ticketForm.issue)
                data.issue = this.$stripHtml(this.ticketForm.issue).trim()
              if(this.ticket.description != this.ticketForm.description)
                data.description = this.ticketForm.description
              if(this.ticket.forms && this.ticket.forms.map(r => r.value).join('|||') != this.ticketForm.forms.map(r => r.value).join('|||'))
                data.forms = this.ticketForm.forms
              if(this.ticket.priority != this.ticketForm.priority)
                data.priority = this.ticketForm.priority
            }
            else{
              if(this.ticket.status != this.ticketForm.status)
                data.status = this.ticketForm.status
              if(this.ticket.project && this.ticket.project._id != this.ticketForm.project || !this.ticket.project)
                data.project = this.ticketForm.project
              if(this.ticket.agent && this.ticket.agent._id != this.ticketForm.agent || !this.ticket.agent)
                data.agent = this.ticketForm.agent
              if(this.ticket.agentPriority != this.ticketForm.agentPriority)
                data.agentPriority = this.ticketForm.agentPriority
              if(this.ticket.issueClassification != this.ticketForm.issueClassification)
                data.issueClassification = this.ticketForm.issueClassification
            }
            break
          case 'acknowledged':
          case 'waitingForClient':
          case 'inProgress':
          case 'escalated':
            if(this.ticket.status != this.ticketForm.status)
              data.status = this.ticketForm.status
            if(this.ticket.agent && this.ticket.agent._id != this.ticketForm.agent || !this.ticket.agent)
              data.agent = this.ticketForm.agent
            if(this.ticket.agentPriority != this.ticketForm.agentPriority)
              data.agentPriority = this.ticketForm.agentPriority
            if(this.ticket.issueClassification != this.ticketForm.issueClassification)
              data.issueClassification = this.ticketForm.issueClassification
            break
          case 'resolved':
            if(forClosure)
              data = {
                status: 'closed'
              }
            else
              data = {
                status: 'pending',
                iterationCount: this.ticket.iterationCount + 1
              }
            break
          default:
            data = {}
            break
        }
        return data
      },
      updateTicket: async function(forClosure, forRelease){
        let data = this.buildUpdateData(forClosure)
        let size = (new TextEncoder().encode(JSON.stringify(data)).length) / 1024 / 1024

        let errors = []

        if(forRelease){
          data.status = 'pending'
          data.acknowledgedAt = null
          data.agent = null
          data.project = null
          data.manager = null
          data.agentPriority = null
          data.forRelease = true
          this.submitTicket(data)
          return 0
        }

        // Check payload size
        if(size > 40)
          errors.push('API payload exceeds the limit. If there are images pasted on the ticket description, please consider adding them as links or attachments.')

        // general validation
        switch(this.ticket.status){
          case 'pending':
          case 'acknowledged':
          case 'waitingForClient':
          case 'inProgress':
          case 'escalated':
            if(this.user._isCustomer && this.ticket.status === 'pending' && data.forms){
              if(this.ticketForm.forms && this.ticketForm.forms.length > 0){
                this.ticketForm.forms.forEach((form) => {
                  if(form.required && form.value && this.$stripHtml(form.value).trim() === '')
                    errors.push(`${form.name} cannot be empty`)
                  else if(form.required && !form.value)
                    errors.push(`${form.name} cannot be empty`)
                });
              }
            }
            if(this.user._isCustomer && data.issue && data.issue.trim() === '')
              errors.push('Issue title cannot be empty')
            if(this.user._isCustomer && data.priority && data.priority.trim() === '')
              errors.push('Severity cannot be empty')
            if(this.user._isCustomer && data.description && this.$stripHtml(data.description).trim() === '')
              errors.push('Issue description cannot be empty')
            if(this.formControl.issue && this.formControl.description && data.issue === this.ticket.issue && data.description === this.ticket.description)
              errors.push('No changes made')
            if(!this.user._isCustomer && data.project && !data.agent && this.formControl.agent)
              errors.push('Assign an agent to this ticket')
            if(!this.user._isCustomer && data.project && !data.agentPriority && this.formControl.agentPriority)
              errors.push('Assign an agent severity to this ticket')
            if(!this.user._isCustomer && (data.agentPriority || data.status !== this.ticket.status) && !data.project && this.formControl.project)
              errors.push('Assign a project to this ticket')
            if(Object.keys(data).length === 0)
              errors.push('No changes made')
            if(!this.user._isCustomer && this.ticket.status === 'pending' && !data.status)
              errors.push('Change the status to proceed')
            break
        }

        // create git ticket
        if(errors.length === 0 && data.status === 'escalated' && !this.ticket.gitLabId){
          await this.createGitTicket()
          data.gitLabId = this.gitLabId
          this.gitLabId = false
          if(!data.gitLabId){
            errors.push('Something went wrong, this user may not have access to the selected GitLab project or your access token may have expired.')
          }
        }
        // close git ticket
        if(errors.length === 0 && this.ticket.gitLabId && ['resolved', 'closed'].includes(data.status)){
          await this.toggleGitTicket('close')
          if(!this.gitLabId)
            errors.push('Something went wrong, please try again.')
          else
            this.gitLabId = false
        }
        // open git ticket (reopened tickets)
        if(errors.length === 0 && this.ticket.gitLabId && data.status === 'pending' && data.iterationCount){
          await this.toggleGitTicket('reopen')
          if(!this.gitLabId)
            errors.push('Something went wrong, please try again.')
          else
            this.gitLabId = false
        }

        if (errors.length > 0){
          errors.reverse().forEach(error => {
            this.$alertify({
              group: 'notification',
              title: 'Ticket Update',
              type: 'warn',
              text: error
            })
          });
          return false;
        }
        else {
          this.submitTicket(data)
        }
      },
      createGitTicket: async function(){
        this.loader(true)
        this.gitLabId = false
        const project = this.ticket.project || this.options.projects.find(r => r.value === this.ticketForm.project).record
        const agent = this.ticket.agent || this.options.agents.find(r => r.value === this.ticketForm.agent).record
        if(!project){
          this.$alertify({
            group: 'notification',
            title: 'Ticket Update',
            type: 'warn',
            text: 'Select a project first'
          })
          return false
        }
        await this.$markDownImages(this.ticket.description, {id: project.gitLab.id, token: agent.gitlabPersonalToken})
        .then(async description => {
          let data = {
            title: `[${this.ticket.ticketNumber}] ${this.ticket.issue}`,
            description: description,
            labels: `From ITS,${this.ticket.issueClassification}`
          }
          try{
            const response = await this.$http.post(
              `${this.$gitEndpoint}/projects/${project.gitLab.id}/issues`,
              data,
              {
                headers: {
                  'Authorization': `Bearer ${agent.gitlabPersonalToken}`,
                  'Access-Control-Allow-Origin' : '*',
                }
              }
            )
            this.gitLabId = response.data.iid
          } catch(err) {
            console.log(err)
          }
        })
        this.loader(false);
      },
      toggleGitTicket: async function(state){
        this.loader(true)
        this.gitLabId = false
        const agent = this.ticketForm.agent ? this.options.agents.find(r => r.value === this.ticketForm.agent).record : this.ticket.agent
        try {
          const response = await this.$http.put(
            `${this.$gitEndpoint}/projects/${this.ticket.project.gitLab.id}/issues/${this.ticket.gitLabId}`,
            { state_event: state },
            {
              headers: {
                'Authorization': `Bearer ${agent.gitlabPersonalToken}`,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          )
          if(response.data.iid)
            this.gitLabId = true
          else
            this.gitLabId = false
        } catch (error) {
          console.log(error)
          this.gitLabId = false
        }
        this.loader(false);
        return this.gitLabId
      },
      submitTicket: async function(data){
        this.loader(true)
        try {
          const response = await this.$http.put(
            `${this.$apiEndpoint}/v1/ticket`,
            {
              ticket: data,
              _ticket: this.ticket
            },
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          if(response.data.ticket._id) {
            await this.getTicket(false)
            response.data.notifications.reverse().forEach(notification => {
              this.$alertify({
                group: 'notification',
                title: 'Ticket Update',
                type: 'success',
                text: notification
              })
            })
            if(response.data.notifications.length === 0)
              this.$alertify({
                group: 'notification',
                title: 'Update successful',
                type: 'success',
                text: 'Your changes have been saved.'
              })
          }
        } catch (error) {
          this.loader(false)
          let error_message = ''
          try{
            error_message = error.response.data.error
            console.log(error.response.data.error)
          } catch {
            error_message = 'Something went wrong, please try again.'
            console.log(error)
          }
          this.$alertify({
            group: 'notification',
            title: 'Update Failed',
            type: 'warn',
            text: error_message
          })
        }
      },
      updateTicketLabels: async function(labels){
        this.loader(true)
        try {
          await this.$http.put(
            `${this.$apiEndpoint}/v1/ticket/labels`,
            {
              ticket: {labels: labels},
              _id: this.ticket._id
            },
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          )
          this.loader(false)
        } catch (error) {
          this.loader(false)
          console.log(error)
        }
      },
      propagateForm: function(){
          let editableFields = ['issue', 'description', 'issueClassification', 'status', 'priority', 'agentPriority']
          this.ticketForm.labels = this.ticket.labels.map(r => this.$labels.find(q => q.value === r))
          this.ticketForm.agent = this.ticket.agent ? this.ticket.agent._id : null
          this.ticketForm.project = this.ticket.project ? this.ticket.project._id : null
          this.ticketForm.forms = this.ticket.forms ? JSON.parse(JSON.stringify(this.ticket.forms)) : []
          this.project = this.ticket.project
          editableFields.forEach(field => {
            this.ticketForm[field] = this.ticket[field]
          })
          this.projectCounter = 1
      },
      filterOptions: function(){
        let status = this.ticket.status
        let statuses = this.$statuses.map(r => r.value)
        this.options.status = this.$statuses.filter(function(value, index){
          return index > statuses.indexOf(status)
        });
        if(status === 'resolved')
          this.options.status.push({ key: 'pending', text: 'Reopen', value: 'pending' })
        if(this.project)
          this.options.agents = this.users.filter(r => r.bunit.map(r => r._id).includes(this.project.bunit)).map(r => {
            return { key: r._id, text: r.name, value: r._id, record: r }
          }).filter(r => r.record.isActive)
        else
          this.options.agents = []
        this.options.status = this.options.status.map(r => {
          r.disabled = r.key == "closed" ? true : false
          return r
        })
        if (status === 'escalated') {
            this.options.status.push({
                key: 'waitingForClient',
                text: 'Waiting for Client',
                value: 'waitingForClient'
            });
            this.options.status = this.options.status.sort((a, b) => {
              if (a.value === 'waitingForClient') return -1;
              if (b.value === 'waitingForClient') return 1;
              if (a.value === 'resolved') return 1;
              if (b.value === 'resolved') return -1;
              return 0;
            });
        }
      },
      imgViewerReady: function(html){
        return html ? html.replace('<img', '<img class="ticket-description-img"') : ''
      },
      initializeViewer: function(){
        document.querySelectorAll('.ticket-description-img').forEach(elem => {
          elem.addEventListener('click', function() {
            window.open(elem.src, '_blank');
          })
        })
      },
      copyTicketNumber: function(){
        this.$alertify({
          group: 'notification',
          title: `Ticket No. ${this.ticket.ticketNumber}`,
          type: 'info',
          text: 'Copied to clipboard!'
        })
        navigator.clipboard.writeText(this.ticket.ticketNumber)
      },
      toggleHistories: function(){
        this.$modal.toggle('ticket-history');
      },
      toggleLinkedTickets: function(){
        this.$modal.toggle('linked-tickets');
      },
      toggleIterationHistories: function(){
        this.$modal.toggle('iteration-history');
      },
      toggleDelete: function(){
        this.$modal.toggle('delete-ticket');
      },
      isStaticProperties: function(status){
        return !(status === 'closed' || this.user.role === 'Customer')
      },
      fileExt: function(filename){
        return filename.split('.').pop().toLowerCase()
      },
      getFileIcon: function(attachment){
        switch(this.fileExt(attachment.filename)){
          case 'doc': case 'docx':
            return 'fa-file-word'
          case 'ppt': case 'pptx':
            return 'fa-file-powerpoint'
          case 'pdf':
            return 'fa-file-pdf'
          case 'xls': case 'xlsx':
            return 'fa-file-excel'
          case 'csv':
            return 'fa-file-csv'
          case 'zip': case 'rar': case '7z':
            return 'fa-file-archive'
          case 'jpeg': case 'jpg': case 'png': case 'gif': case 'tiff': case 'bmp': case 'img': case 'ico': case 'svg': case 'eps': case 'raw':
            return 'fa-file-image'
          case 'mp4': case 'mov': case 'avi': case 'flv': case 'wmv': case 'swf': case 'mkv': case 'mpeg':
            return 'fa-file-video'
          case 'txt': case 'html': case 'css': case 'js': case 'vue': case 'ts': case 'xml':
            return 'fa-file-code'
          default:
            return 'fa-file'
        }
      },
      validateUser: async function(isValid, exists){
        if(isValid){
          this.user._isAdmin = (this.user.role === "Admin")
          this.user._isCustomer = (this.user.role === "Customer")
          this.user._isAgent = (this.user.role === "Agent")
          this.user._isManager = (this.user.role === "Manager")
          this.user._isManagerAgent = (this.user.role === "Manager" && this.user.isAgent)
          this.user._isAssignedAgent =
           (this.ticket && this.ticket.agent ? (this.user._id === this.ticket.agent._id) : false) &&
           (this.user._isAgent || this.user._isManagerAgent)
          this.user._allowDelete = !this.user.restrictions.delete
          this.user._allowReassign = !this.user.restrictions.reassign
       }
       else{
         this.$alertify({
           group: 'notification',
           title: `Access denied`,
           type: 'warning',
           text: exists ? `You don't have access to this ticket.` : `Ticket does not exist`
         })
         await this.$sleep(500);
         this.$router.push('/tickets')
       }
      },
      setForms: function(){
        switch(this.ticket.status){
          case 'pending':
            this.formControl.issue = this.user._isCustomer && this.ticket.iterationCount === 1
            this.formControl.description = this.user._isCustomer && true
            this.formControl.status = this.user._isManager
            this.formControl.project = this.user._isManager && this.ticket.iterationCount === 1
            this.formControl.agent = this.user._isManager
            this.formControl.issueClassification = this.user._isManager && this.ticket.iterationCount === 1
            this.formControl.priority = this.user._isCustomer && true
            this.formControl.agentPriority = this.user._isManager && this.ticket.iterationCount === 1
            this.formControl.reopen = false
            this.formControl.labels = !this.user._isCustomer
            this.formControl.button = this.user._isCustomer ? 'Save' : 'Update'
            break
          case 'acknowledged':
            this.formControl.issue = false
            this.formControl.description = false
            this.formControl.status = this.user._isAssignedAgent
            this.formControl.project = false
            this.formControl.agent = this.user._isManager
            this.formControl.issueClassification = this.user._isAssignedAgent && this.ticket.iterationCount === 1
            this.formControl.priority = false
            this.formControl.agentPriority = this.user._isAssignedAgent && this.ticket.iterationCount === 1
            this.formControl.reopen = false
            this.formControl.labels = !this.user._isCustomer
            this.formControl.button = 'Update'
            break
          case 'inProgress':
          case 'escalated':
            this.formControl.issue = false
            this.formControl.description = false
            this.formControl.status = this.user._isAssignedAgent
            this.formControl.project = false
            this.formControl.agent = this.user._isManager
            this.formControl.issueClassification = this.user._isAssignedAgent && this.ticket.iterationCount === 1
            this.formControl.priority = false
            this.formControl.agentPriority = this.user._isAssignedAgent && this.ticket.iterationCount === 1
            this.formControl.reopen = false
            this.formControl.labels = !this.user._isCustomer
            this.formControl.button = 'Update'
            break
          case 'resolved':
            this.formControl.issue = false
            this.formControl.description = false
            this.formControl.status = false
            this.formControl.project = false
            this.formControl.agent = false
            this.formControl.issueClassification = false
            this.formControl.priority = false
            this.formControl.agentPriority = false
            this.formControl.reopen = true
            this.formControl.labels = !this.user._isCustomer
            this.formControl.button = 'Reopen'
            break
          case 'closed':
            this.formControl.issue = false
            this.formControl.description = false
            this.formControl.status = false
            this.formControl.project = false
            this.formControl.agent = false
            this.formControl.issueClassification = false
            this.formControl.priority = false
            this.formControl.agentPriority = false
            this.formControl.reopen = false
            this.formControl.labels = false
            this.formControl.button = false
            break
          case 'waitingForClient':
            this.formControl.issue = false;
            this.formControl.description = false;
            this.formControl.status = this.user._isAssignedAgent;
            this.formControl.project = false;
            this.formControl.agent = this.user._isManager;
            this.formControl.issueClassification = false;
            this.formControl.priority = false;
            this.formControl.agentPriority = false;
            this.formControl.reopen = false;
            this.formControl.labels = !this.user._isCustomer;
            this.formControl.button = 'Update';
            break;
        }
        if(this.user._isAdmin)
          this.formControl = {
            issue: false,
            description: false,
            status: false,
            project: false,
            agent: false,
            issueClassification: false,
            priority: false,
            agentPriority: false,
            reopen: false,
            button: false
          }
      }
    },
    filters: {
      formatDate: function (date){
        return moment(date, 'YYYY/MM/DD HH:mm:ss').format("ddd, DD MMMM YYYY, hh:mm A")
      },
      ticketState: function(val){
        return val === 'closed' ? 'Closed' : 'Open'
      },
      priorityName: function(val){
        switch(parseInt(val)){
          case 1: return 'Critical'
          case 2: return 'Major'
          case 3: return 'Moderate'
          case 4: return 'Low'
          default: return 'Unclassified'
        }
      },
      capitalize: function(val){
        return val.capitalize()
      },
      convertSize: function(a, b = 2){
        if(0===a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return parseFloat((a/Math.pow(1024,d)).toFixed(c))+" "+["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]
      }
    },
    watch: {
      '$data.ticketForm.labels': {
        handler: function(newVal) {
          this.updateTicketLabels(newVal.map(r => r.value))
        },
        deep: true
      },
      '$data.ticketForm.project': {
        handler: function(newVal, oldVal) {
          this.project = this.projects.find(r => r._id === newVal);
          if(this.project)
            this.options.agents = this.users.filter(r => r.bunit.map(r => r._id).includes(this.project.bunit._id)).map(r => {
              return { key: r._id, text: r.name, value: r._id, record: r }
            }).filter(r => r.record.isActive)
          if(JSON.stringify(oldVal) !== '{}')
            this.ticketForm.agent = null;

        },
        deep: true
      }
    },
    mounted: function() {
      document.title = `${this.ticketNumber} | iRipple Helpdesk`;
      this.getTicket(true)
    },
    updated: function() {
      this.initializeViewer();
    }
  }
</script>
