<template>
  <div class="p-5 history-modal text-center">
    This ticket will be permanently deleted. Are you sure you want to continue?<br><br>
    <div class="w-100 mt-3">
      <div class="btn border-0 px-4 py-2 btn-primary" v-on:click="deleteTicket">Continue</div>&nbsp;
      <div class="btn border-0 px-4 py-2 btn-dark bg-escalated" v-on:click="cancelMethod">Cancel</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      id: String,
      ticketNumber: String
    },
    data() {
      return {
      }
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      cancelMethod: function(){
        this.$modal.toggle('delete-ticket');
      },
      deleteTicket: async function(){
        this.loader(true)
        try {
          const response = await this.$http.post(
            `${this.$apiEndpoint}/ticket/remove_ticket`,
            {
              _id: this.id,
              ticketNumber: this.ticketNumber,
            },
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          )
          if(response && response.data && response.data.message){
            this.$alertify({
              group: 'notification',
              title: 'Delete Ticket',
              type: response.data.error ? 'warn' : 'success',
              text: response.data.message
            })
            if(!response.data.error)
              this.$router.push(`/tickets/`)
          }
        } catch (error) {
          this.loader(false)
          this.$alertify({
            group: 'notification',
            title: 'Update Failed',
            type: 'warn',
            text: 'Ticket delete failed.'
          })
        }
      }
    }
  }
</script>
