<template>
  <div class="text-dark bg-light">
    <div class="btn float-right text-escalated mt-2 mx-2" v-on:click="toggleLookUp">
      <h3><i class="fas fa-close"></i></h3>
    </div>
    <div class="btn float-left mt-3 mx-2" v-on:click="toggleLookUp">
      <h6 class="text-header-static">
        Ticket lookup
      </h6>
    </div>
    <div class="w-100">
      <SearchComponent
        @searchRecord="searchTicket($event)"
        :placeholder="'Search tickets by title or ticket number'"
        :showSideOptions="false"
        :isRealTime="false"
      ></SearchComponent>
      <div class="w-100 lookup-list bg-light">
        <div class="p-5 w-100 text-center" v-if="busy">
          <i class="fas fa-spinner animate-spin"></i>
        </div>
        <div class="ticket-holder">
          <div class="w-100 border-bottom" v-for="ticket in tickets" v-bind:key="ticket._id">
            <div class="ticket-details px-3 pt-3 pb-4" v-bind:class="[linkType ? 'cursor-pointer' : '']" v-on:click="addTicket(ticket)">
              <div class="ticket-header">
                <span class="cursor-pointer" v-tooltip="ticket.isActive ? `Ticket is currently active` : `Ticket has been inactive for a while`">
                  <i class="far fa-check-circle" v-bind:class="ticket.isActive ? `text-grass` : `text-secondary`"></i>
                </span>&nbsp;
                <span>{{ticket.ticketNumber}}</span> |
                {{ticket.issue}}
              </div>
              <div class="">
                {{ticket.client.name}}
              </div>
              <div v-if="ticket.agent">
                Assigned to {{ticket.agent.name}}
              </div>
              <div class="mt-1 p-0">
                <label v-bind:class="['btn btn-primary border-0 bg-' + ticket.status]">{{ticket.status}}</label>
                <label v-if="ticket.issueClassification" class="btn btn-primary border-0 bg-primary-default">{{ticket.issueClassification}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import SearchComponent from '../Search.vue'

  export default {
    props: {
      ref_ticket: Object,
      linkType: String
    },
    components: {
      SearchComponent
    },
    data() {
      return {
        tickets: [],
        search: '',
        busy: false
      }
    },
    methods: {
      toggleLookUp: function(){
        this.$emit('toggleLookUp', {})
      },
      addTicket: function(ticket){
        if(this.linkType){
          this.$emit('addTicket', {
            ticket: ticket,
            linkType: this.linkType
          })
          this.toggleLookUp()
        }
      },
      searchTicket: function(val){
        this.search = val
        if(this.search.trim() !== '')
          this.getTickets()
      },
      async getTickets(){
        this.busy = true
        try {
          console.log(`${this.$apiEndpoint}/v1/tickets?sudo=${this.ref_ticket ? 'false' : 'true'}`)
          const response = await this.$http.post(
            `${this.$apiEndpoint}/v1/tickets?sudo=${this.ref_ticket ? 'false' : 'true'}`,
            { search: this.search },
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          )
          if(response)
            this.tickets = response.data.tickets
          if(this.ref_ticket)
            this.tickets = this.tickets.filter(r => r._id !== this.ref_ticket._id)
          if(this.tickets.length === 0)
            this.$alertify({
              group: 'notification',
              title: `Ticket Lookup`,
              type: 'warning',
              text: `No tickets found`
            })
        } catch (error) {
          console.log(error)
        }
        this.busy = false
      },
      formatDate: function (date){
        return moment(date, 'YYYY/MM/DD HH:mm:ss').format("MMM DD, YYYY hh:mm A")
      }
    },
    filters: {
      priorityName(val) {
        switch(parseInt(val)){
          case 1: return 'Critical'
          case 2: return 'Major'
          case 3: return 'Moderate'
          case 4: return 'Low'
          default: return 'Unclassified'
        }
      }
    }
  }
</script>
