<template>
  <div class="pt-4 bg-light row">
    <div>
      <!-- Parent ticket -->
      <div v-if="parent._id" class="col mb-3" style="padding-left: calc(3em + 40px); padding-right: 3em;">
        <div>
          <i class="fas fa-home" style="position: absolute; left: 3.75em;"></i>
          <h6 class="text-header-static m-0">
            <a class="text-header-static" v-tooltip="{ content: 'Go to ticket page', placement: 'right' }" :href="`/tickets/${parent.ticketNumber}`">{{parent.issue}}</a>
            <div class="float-right btn btn-sm btn-warning" v-on:click="removeTicket(parent, 'parent')" v-if="user.role !== 'Customer'">
              <i class="fas fa-minus"></i>
            </div>
          </h6>
          <p class="my-1">
            <small class="text-secondary">
              {{parent.ticketNumber}}
              <br>
              Last updated {{parent.updatedAt | dateEstimate}}
            </small>
          </p>
          <div class="btn btn-sm border-rounded border-0 btn-dark py-0 px-2" v-bind:class="`bg-${parent.status}`">
            <small>{{ $statusTitle[parent.status] }}</small>
          </div>&nbsp;
          <span v-for="label in parent.labels.map(r => $labels.find(q => q.value === r))" v-bind:key="label._id">
            <div class="btn btn-sm border-rounded border-0 btn-primary py-0 px-2">
              <small>{{ label.value }}</small>
            </div>&nbsp;
          </span>
        </div>
      </div>
      <div v-else-if="user.role !== 'Customer'" class="col p-3 pt-0 mt-2 text-header text-center">
        <div class="btn btn-sm btn-primary border-rounded px-3" v-on:click="toggleLookUp('parent')">
          <small>Add parent ticket &nbsp;&nbsp;<i class="fas fa-plus"></i></small>
        </div>
      </div>
      <div class="text-center my-2">
        <i class=" fas fa-ellipsis-h"></i>
      </div>
      <!-- Main ticket -->
      <div class="col col-12 px-5 bg-white py-4" style="border-left: 10px solid #0b5ed7">
        <h6 class="text-header-static m-0">
          {{ticket.issue}}
        </h6>
        <p class="my-1">
          <small class="text-secondary">
            {{ticket.ticketNumber}}
            <br>
            Last updated {{ticket.updatedAt | dateEstimate}}
          </small>
        </p>
        <div class="btn btn-sm border-rounded border-0 btn-dark py-0 px-2" v-bind:class="`bg-${ticket.status}`">
          <small>{{ $statusTitle[ticket.status] }}</small>
        </div>&nbsp;
        <span v-for="label in ticket.labels.map(r => $labels.find(q => q.value === r))" v-bind:key="label._id">
          <div class="btn btn-sm border-rounded border-0 btn-primary py-0 px-2">
            <small>{{ label.value }}</small>
          </div>&nbsp;
        </span>
      </div>
      <div class="text-center my-2">
        <i class=" fas fa-ellipsis-h"></i>
      </div>
      <!-- Child tickets -->
      <div v-if="children.length > 0">
        <div v-for="child in children" v-bind:key="child._id" class="col mb-5" style="padding-left: calc(3em + 40px); padding-right: 3em;">
          <div>
            <i class="fas fa-link" style="position: absolute; left: 3.75em;"></i>
            <h6 class="text-header-static m-0">
              <a class="text-header-static" v-tooltip="{ content: 'Go to ticket page', placement: 'right' }" :href="`/tickets/${child.ticketNumber}`">{{child.issue}}</a>
              <div class="float-right btn btn-sm btn-warning" v-on:click="removeTicket(child, 'child')" v-if="user.role !== 'Customer'">
                <i class="fas fa-minus"></i>
              </div>
            </h6>
            <p class="my-1">
              <small class="text-secondary">
                {{child.ticketNumber}}
                <br>
                Last updated {{child.updatedAt | dateEstimate}}
              </small>
            </p>
            <div class="btn btn-sm border-rounded border-0 btn-dark py-0 px-2" v-bind:class="`bg-${child.status}`">
              <small>{{ $statusTitle[child.status] }}</small>
            </div>&nbsp;
            <span v-for="label in child.labels.map(r => $labels.find(q => q.value === r))" v-bind:key="label._id">
              <div class="btn btn-sm border-rounded border-0 btn-primary py-0 px-2">
                <small>{{ label.value }}</small>
              </div>&nbsp;
            </span>
          </div>
        </div>
      </div>
      <div class="col p-3 text-header text-center" v-if="user.role !== 'Customer'">
        <div class="btn btn-sm btn-primary border-rounded px-3" v-on:click="toggleLookUp('child')">
          <small>Add child ticket &nbsp;&nbsp;<i class="fas fa-plus"></i></small>
        </div>
      </div>
    </div>
    <modal
      name="ticket-lookup"
      :transition="`fade`"
      :width="`350px`"
      :height="`auto`"
      :scrollable="false"
      :clickToClose="false"
      class="ticket-lookup"
    >
      <TicketLookUpComponent :ref_ticket="ticket" :linkType="linkType" @addTicket="addTicket($event)" @toggleLookUp="toggleLookUp($event)"></TicketLookUpComponent>
    </modal>
  </div>
</template>

<script>
  import moment from 'moment'
  import TicketLookUpComponent from '../TicketLookUp.vue'

  export default {
    components: {
      TicketLookUpComponent
    },
    props: {
      ticket: Object
    },
    data() {
      return {
        user: this.$userData ? this.$userData.user : {},
        parent: {},
        children: [],
        linkType: 'parent'
      }
    },
    methods:{
      loader: function(val){
          this.$emit('loader', val)
      },
      toggleLookUp: function(linkType){
        this.linkType = linkType
        this.$modal.toggle('ticket-lookup')
      },
      addTicket: function(params){
        if(params.linkType === "parent"){
          if(this.children.map(r => r._id).includes(params.ticket._id))
            this.$alertify({
              group: 'notification',
              title: 'Link Ticket',
              type: 'warn',
              text: `${params.ticket.ticketNumber} is already tagged as a child of ${this.ticket.ticketNumber}`
            })
          else
            this.modifyTicketLink("parent", params.ticket, "add")
        }
        else if(params.linkType === "child"){
          if(this.children.map(r => r._id).includes(params.ticket._id))
            this.$alertify({
              group: 'notification',
              title: 'Link Ticket',
              type: 'warn',
              text: `${params.ticket.ticketNumber} is already tagged as a child of ${this.ticket.ticketNumber}`
            })
          else if(this.parent._id === params.ticket._id)
            this.$alertify({
              group: 'notification',
              title: 'Link Ticket',
              type: 'warn',
              text: `${params.ticket.ticketNumber} is already tagged as ${this.ticket.ticketNumber}'s parent.`
            })
          else{
            this.modifyTicketLink("child", params.ticket, "add")
          }
        }
      },
      removeTicket: function(ticket, linkType){
        if(linkType === "parent"){
          this.parent = {}
          this.modifyTicketLink("parent", ticket, "remove")
        }
        else if(linkType === "child"){
          this.children = []
          this.modifyTicketLink("child", ticket, "remove")
        }
      },
      modifyTicketLink: async function(type, linkedTicket, action){
        this.loader(true)
        try {
          await this.$http.put(
            `${this.$apiEndpoint}/v1/ticket/link`,
            {
              type: type,
              action: action,
              ticket: this.ticket._id,
              _ticket: linkedTicket._id
            },
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          )
          this.loader(false)
          this.$alertify({
            group: 'notification',
            title: 'Link Ticket',
            type: 'success',
            text: `${linkedTicket.ticketNumber} was successfully ${action === 'add' ? 'linked' : 'unlinked'}`
          })
          await this.getTicketLinks()
        } catch (error) {
          this.loader(false)
          this.$alertify({
            group: 'notification',
            title: 'Link Ticket',
            type: 'error',
            text: `Something went wrong. Please try again.`
          })
          console.log(error)
        }
      },
      async getTicketLinks(){
        this.loader(true)
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/ticket/links/${this.ticket._id}`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.parent = response.data.parent || {}
          this.children = response.data.children || []
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      }
    },
    mounted: function() {
      this.getTicketLinks()
    },
    filters: {
      dateEstimate(date) {
        return moment(date, 'YYYY/MM/DD HH:mm:ss').fromNow();
      }
    }
  }
</script>
