<template>
  <div class="p-5 history-modal">
    <div v-if="histories && histories.length > 0">
      <label class="text-header" v-for="(history, i) in histories" v-bind:key="history._id">
        <i class="far fa-circle"></i><span v-html="buildMessage(history, i)"></span>
        <div>{{history.createdAt | formatDate}}</div>
      </label>
    </div>
    <label class="text-center text-header p-0 m-0" v-else>
      No history yet
    </label>
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    props: {
      unfilteredHistories: Array
    },
    data() {
      return {
        histories: []
      }
    },
    methods: {
      buildMessage: function (record, i){
        this.$console(record)
        let prevRecord = this.histories[i - 1]
        let prevAgent = prevRecord && prevRecord.details.agent ? prevRecord.details.agent._id ? prevRecord.details.agent._id : prevRecord.details.agent : ''
        let currentAgent = record && record.details.agent ? record.details.agent._id ? record.details.agent._id : record.details.agent : ''
        let messages = []
        if(record.action === 'Create')
          messages.push(`Ticket was created`)
        else if(record.details && record.details.v1){
          if(record.details.status === 'pending' && record.details.iterationCount === 1)
            messages.push(`Ticket was created`)
          else if(record.details.status === 'pending' && record.details.iterationCount > 1){
            messages.push(`Ticket marked as an open issue`)
            messages.push(`Changed status to ${record.details.status !== 'inProgress' ? record.details.status : 'in progress'}`)
          }
          else if(record.sender.roles === "Customer")
            messages.push(`${record.sender.name} updated the ticket`)
          else if(record.details.status === 'closed')
            messages.push(`Ticket was closed`)
          else if(record.details.status && record.details.status !== 'closed')
            messages.push(`Changed status to ${record.details.status !== 'inProgress' ? record.details.status : 'in progress'}`)
          if(record.details.agent && !(record.details.pre && record.details.pre.agent) && record.details.agent)
            messages.push(`Assigned ticket to ${record.agent.name}`)
          else if(record.details.agent && record.details.pre && record.details.pre.agent && record.details.agent != record.details.pre.agent._id)
            messages.push(`Reassigned ticket to ${record.agent.name}`)
        }
        else if(record.sender){
          if(record.agent && record.details.status === "acknowledged" && record.details.iterationCount === 1)
            messages.push(`Assigned to ${record.agent.name}`)
          if(record.details.status === 'pending' && record.details.iterationCount > 1 && prevAgent && currentAgent && prevAgent === currentAgent)
            messages.push(`${record.sender.name} reopened the ticket`)
          else if(["pending", 'acknowledged', 'inProgress'].includes(record.details.status) && prevAgent && currentAgent && prevAgent !== currentAgent)
            messages.push(`${record.sender.name} reassigned ticket to ${record.agent.name}`)
          else if(record.details.status === 'pending' && record.sender.roles === "Customer")
            messages.push(`${record.sender.name} updated the ticket`)
          else if(record.details.status === 'pending' && record.sender.roles !== "Customer" && record.details.iterationCount > 1)
            messages.push(`${record.sender.name} marked the ticket as an open issue`)
          else if(record.details.status === 'closed' && record.sender.name === "Administrator"){
            messages.push(`Ticket was automatically closed due to inactivity.`)
          }
          else
            messages.push(`Changed status to ${record.details.status !== 'inProgress' ? record.details.status : 'in progress'}`)
        }
        else{
          if(record.details.status === 'closed')
            messages.push(`Closed due to inactivity`)
          else if(record.details.status === 'pending')
            messages.push(`Ticket was created`)
        }
        return messages.join('<br><span style="margin-left: 17.5px"></span>')
      }
    },
    filters: {
      formatDate: function (date){
        return moment(date, 'YYYY/MM/DD HH:mm:ss').format("ddd, DD MMMM YYYY, hh:mm A")
      }
    },
    mounted: function() {
      this.histories = this.unfilteredHistories.filter((record,i) => {
        return this.buildMessage(record, i).length > 0
      })
    }
  }
</script>
