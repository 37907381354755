<template>
  <div class="p-5 row">
    <div class="col col-auto px-1">
      <h4>{{ticket.gitTicket.iid}}</h4>
    </div>
    <div class="col col-auto-lg py-1 px-4">
      <h6 class="text-header-static m-0">
        {{ticket.gitTicket.title}}
      </h6>
      <p class="my-1">
        <small class="text-secondary">
          {{ticket.project.gitLab.title}}
          <br>
          By {{ticket.gitTicket.author.name}}, updated {{ticket.gitTicket.updated_at | dateEstimate}}
        </small>
      </p>
      <div class="btn btn-sm border-rounded border-0 btn-dark py-0 px-2" v-bind:class="ticket.gitTicket.state === 'opened' ? 'bg-pending' : 'bg-closed'">
        <small>{{ ticket.gitTicket.state | capitalize }}</small>
      </div>&nbsp;
      <span v-for="label in ticket.gitTicket.labels" v-bind:key="label">
        <div class="btn btn-sm border-rounded border-0 btn-primary py-0 px-2">
          <small>{{ label }}</small>
        </div>&nbsp;
      </span>
      <div class="pt-4">
        <a :href="ticket.gitTicket.web_url" target="_blank" class="btn btn-sm border-rounded border-0 btn-light bg-white text-muted p-0">
          <small>View more details on Gitlab &nbsp;&nbsp;<i class="fas fa-chevron-right"></i></small>
        </a>
      </div>
      <hr class="my-4">
      <h6 class="text-header mb-4">Linked Issues</h6>
      <a v-for="link in links" v-bind:key="link.id" :href="link.web_url" target="_blank" v-tooltip="{ content: 'Go to Gitlab page', placement: 'right' }">
        <h6 class="text-header-static m-0">
          {{link.iid}}&nbsp;&nbsp;{{link.title}}
        </h6>
        <p class="my-1">
          <small class="text-secondary">
            {{link.web_url | projectString | capitalize}}
            <br>
          </small>
        </p>
        <div class="btn btn-sm border-rounded border-0 btn-dark py-0 px-2" v-bind:class="link.state === 'opened' ? 'bg-pending' : 'bg-closed'">
          <small>{{ link.state | capitalize }}</small>
        </div>&nbsp;
        <span v-for="linkLabel in link.labels" v-bind:key="linkLabel">
          <div class="btn btn-sm border-rounded border-0 btn-primary py-0 px-2">
            <small>{{ linkLabel }}</small>
          </div>&nbsp;
        </span>
      </a>
      <div class="text-center text-muted" v-if="busy">
        <small><i class="fas fa-spinner animate-spin"></i>&nbsp;Loading</small>
      </div>
      <div class="text-center text-muted" v-if="!busy && links.length === 0">
        <small>No issues linked to this ticket</small>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    props: {
      ticket: Object
    },
    data() {
      return {
        links: [],
        busy: true
      }
    },
    methods: {
      getLinks: async function(){
        this.busy = true
        try {
          const response = await this.$http.get(
            `${this.$gitEndpoint}/projects/${this.ticket.project.gitLab.id}/issues/${this.ticket.gitLabId}/links`,
            {
              headers: {
                'Authorization': `Bearer ${this.$gitToken}`,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.links = response.data
        } catch (error) {
          console.log(error)
        }
        this.busy = false
      }
    },
    filters: {
      dateEstimate(date) {
        return moment(date, 'YYYY/MM/DD HH:mm:ss').fromNow();
      },
      projectString: function(val){
        return val.split("tools.iripple.com/")[1].split("/-/")[0]
      },
      capitalize: function(val){
        return val.capitalize()
      }
    },
    mounted: function() {
      this.getLinks()
    }
  }
</script>
